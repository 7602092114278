import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { ValidatorsService } from '../../shared/services/validators.service';
import { UserSignUpRequest } from '../../shared/intarfaces';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss']
})
export class SignupPageComponent implements OnInit {
  userData: UserSignUpRequest = {} as UserSignUpRequest;
  theme$ = this.themeService.theme$;

  private readonly EMAIL_REGEX: string = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}';
  emailMinLength = 3;
  emailMaxLength = 500;
  nameMinLength = 2;
  nameMaxLength = 50;

  form = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(this.emailMinLength),
      Validators.maxLength(this.emailMaxLength),
      Validators.pattern(this.EMAIL_REGEX)
    ]),
    password: new FormControl('', this.validatorsService.passwordValidators(true, false)),
    firstName: new FormControl('', [
      Validators.required,
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$')
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$')
    ]),
    agreement: new FormControl(false, [Validators.requiredTrue])
  });

  get passwordControl() {
    return this.form.get('password')!;
  }

  constructor(
    public validatorsService: ValidatorsService,
    public authService: AuthService,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    const userData = this.authService.getPresetUserData();
    if (userData) {
      this.userData = userData;
      (Object.keys(this.userData) as Array<keyof UserSignUpRequest>).forEach((key) => {
        const control = this.form.get(key);
        if (control) {
          control.setValue(this.userData[key] ?? '');
        }
      });
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.authService.hashPassword(this.form.value.password!, this.form.value.email!).then((hash) => {
      const userSignUpRequest = {
        ...this.form.value,
        password: hash
      } as UserSignUpRequest;

      if (this.userData?.imageUrl) {
        userSignUpRequest.imageUrl = this.userData.imageUrl;
      }

      if (this.userData?.username) {
        userSignUpRequest.username = this.userData.username;
      }

      this.authService.sendRegisterRequest(userSignUpRequest).subscribe((response) => {
        if (response.success && response.data) {
          this.authService.successAuthorize(response.data);
        }
      });
    });
  }
}
